import React, { useState, useEffect } from "react"

import ScotomalabLogo from "../images/scotomalabwhite.svg"

import Email from "../images/icons/Email.svg"
import Location from "../images/icons/Location.svg"
import Telephone from "../images/icons/Telephone.svg"
import Instagram from "../images/icons/Instagram.svg"

import styled from "styled-components"

const ScotomaLogo = styled.img`
  width: 100vw;
  padding: 1rem;
  box-sizing: border-box;
  margin-top: 10rem;

  img {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 576px) {
    padding: 3rem;
  }
`
const Contact = styled.div`
  font-family: "Averta Regular";
  font-size: 1rem;
  color: white;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 2rem 1rem;
  box-sizing: border-box;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    width: 0px;
  }

  > div > a {
    cursor: pointer;
    color: white;
    text-decoration: none;
  }

  div {
    display: flex;
  }

  div:nth-child(2) {
    justify-content: center;
  }

  div:nth-child(3) {
    justify-content: center;
  }

  div:nth-child(4) {
    justify-content: flex-end;
  }

  span {
    position: relative;
    left: 0.2rem;
    white-space: nowrap;
  }

  @media (min-width: 576px) {
    padding: 2rem 3rem;
  }

  @media (max-width: 576px) {
    span {
      display: none;
    }
  }
`

const Icon = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  display: inline-block;
  img {
    width: 100%;
    height: 100%;
  }
`

const HoverSwitch = styled.div`
  display: flex;
  align-items: center;
  pointer-events: all;
`

const Footer = () => {
  const [hoverActive, setHoverActive] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
  })

  const toggleHover = index => {
    // let newHoverActive = hoverActive
    // newHoverActive[index] = !newHoverActive[index]
    if (window.innerWidth <= 576) return
    setHoverActive({
      ...hoverActive,
      [index]: !hoverActive[index],
    })
  }

  useEffect(() => {}, [hoverActive])

  return (
    <>
      <ScotomaLogo src={ScotomalabLogo} />
      <Contact>
        <div>
          <a href="mailto:hello@scotomalab.com">
            <HoverSwitch
              onMouseEnter={() => toggleHover(0)}
              onMouseLeave={() => toggleHover(0)}
            >
              <Icon>
                <img src={Email} />
              </Icon>
              {!hoverActive[0] ? (
                <span>Write</span>
              ) : (
                <span>hello@scotomalab.com</span>
              )}
            </HoverSwitch>
          </a>
        </div>
        <div>
          <a href="https://instagram.com/scotomalab" target="_blank">
            <HoverSwitch
              onMouseEnter={() => toggleHover(3)}
              onMouseLeave={() => toggleHover(3)}
            >
              <Icon>
                <img src={Instagram} />
              </Icon>
              {!hoverActive[3] ? (
                <span>Instagram</span>
              ) : (
                <span>@scotomalab</span>
              )}
            </HoverSwitch>
          </a>
        </div>
        <div>
          <a href="tel:+33 (0)6 12 50 12 43">
            <HoverSwitch
              onMouseEnter={() => toggleHover(1)}
              onMouseLeave={() => toggleHover(1)}
            >
              <Icon>
                <img src={Telephone} />
              </Icon>
              {!hoverActive[1] ? (
                <span>Call</span>
              ) : (
                <span>+33 (0)6 12 50 12 43</span>
              )}
            </HoverSwitch>
          </a>
        </div>
        <div>
          <a href="https://goo.gl/maps/kykFQrowEF1m8HXMA" target="_blank">
            <HoverSwitch
              onMouseEnter={() => toggleHover(2)}
              onMouseLeave={() => toggleHover(2)}
            >
              <Icon>
                <img src={Location} />
              </Icon>
              {!hoverActive[2] ? (
                <span>Visit</span>
              ) : (
                <span>82 Rue Albert, 75013 Paris</span>
              )}
            </HoverSwitch>
          </a>
        </div>
      </Contact>
    </>
  )
}

export default Footer
